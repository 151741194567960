

.servicecard {
    box-shadow: 0 4px 8px 0 rgba(218, 15, 15, 0.2);
    padding: 16px;
    text-align: center;
    /* background-color: #f1f1f1; */
    margin-bottom: 10px;
    margin-top: 10px;
    
  }
  .videobrsr{
    height: 80%;
    width:inherit;
  }
  .servicecard h5{
    text-decoration: underline #00CCCC 5px;
    font-weight: bold;
  }