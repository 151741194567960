.column {
    float: left;
    width: 30%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }