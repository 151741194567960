

.data {
  width: 100%;
  height: auto;
  position: relative;
  background: rgba(10, 109, 190, 0.1);
}

.data:before {
  content: '';
  position: absolute;
  /* background: url('../../../../public/images/data-bg.png')no-repeat center center/cover; */
  height: 100%;
  width: 100%;
  z-index: -1;
}

.data .content {
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  position: absolute;
  /* text-align: justify; */
  /* left: 50%;
  top: 30%; */
  /* width: 100%; */
}
.underlineImg{
  text-align: center;
  height: 5px;
  width: 10px;
}
.data .container {
  display: grid;
  grid-template-columns: repeat(1,  3fr);
  padding: 1rem;
  width: 100%;
  margin-bottom: 10px  !important;
  height: auto;
  /* background-color: red; */
}
.data .content p {
  margin: 1rem 0;
}
 .container p {
  /* margin: 1rem 0; */
  text-align: justify;
  width: 100%;
}
.img-fluid {
  max-width: 50%;
  max-height: 5px;
}
.client1logo{
  width:120px;
  height:100px;
  text-align: center !important;
  margin: auto !important;
}
.client2logo{
  width:200px;
  height:100px;
}
.client3logo{
  width:180px;
  height:100px;
}
@media screen and (max-width: 940px) {
  .data .content {
      left: 0;
      width: 100%;
      /* padding: 1rem; */
      background: rgba(255, 255, 255, .7);
      height: auto;
  }
  .data.container {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    width: 100%;
    padding: 0;
  }
}










