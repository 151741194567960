.footerContact {
  background-color: #74EBD5;
  background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  padding: 20px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #39527fd2;
  padding: 50px 0;
  color: #fff;
}
footer .container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  grid-gap: 20px;
}
footer .logo {
  width: 150px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: white;
  margin: 10px 0;
}
footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
}
footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}
.legal {
  text-align: center;
  padding: 20px;
  background-color: #39517F;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
