@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family:sans-serif; 
  background-color: #fff;
  animation-duration: 0s;
  
  
}

.container {
  max-width: 90%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  border-radius: 5px;
  padding: 10px 30px;
  background: #27ae60;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}
button i {
  margin-right: 5px;
}
.heading {
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  
}
.heading h1 {
  color: #2d3954;
  text-decoration: underline #00CCCC 5px;
  margin-top: 10px;
  margin-left: 20px !important;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 25px;
  text-align: center;
}
.heading p {
  color: #ff6922;
  margin-left: 20px;
  margin-top: 10px;
  text-align: center;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 40px 0;
  position: relative;
  background: #f7f9fc;
  
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 40px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn2 {
  border-radius: 50px;
  font-size: 15px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
  padding: 10px ;
  color: white;
  font-size: 20px;
  font-weight: 400;
  border: 1px solid #27ae601f;
  background: #037946;
  text-decoration: none;
  margin-top: 10px !important;
}
.btn6 {
  border-radius: 50px;
  padding: 10px ;
  color: white;
  font-size: 20px;
  font-weight: 400;
  border: 1px solid #4a94f51f;
  background: #096f97;
  text-decoration: none;
  margin-top: 10px !important;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  /* background: rgba(17, 40, 72, 0.629);
  z-index: -1; */
}
.back .container {
  color: #fff;
  padding: 120px 0;
  /* height: 40vh; */
}
.back h1 {
  font-size: 26px;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}
@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(1, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
