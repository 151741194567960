header {
  background-color: #39517f;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  position: sticky;
  top: 0;
  z-index: 99999;
  padding: 15px 0;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .logo img {
  height: 100px;
  width: 200px;
}

header .nav-menu {
  display: flex;
  list-style: none;
}

header .nav-menu li {
  margin-left: 30px;
}

header .nav-menu a {
  text-decoration: none;
  font-weight: 500;
  color: white;
  transition: 0.3s;
}

header .nav-menu a:hover {
  color: #b05037;
}

header .toggle {
  display: none;
}

/* ===== Responsive Styles ===== */
@media screen and (max-width: 768px) {
  header {
    padding: 10px 0;
  }

  header .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #39517f;
    text-align: center;
    padding: 20px 0;
    transition: all 0.3s ease-in-out;
  }

  header .nav-menu.open {
    display: flex;
  }

  header .nav-menu li {
    margin: 15px 0;
  }

  header .toggle {
    display: block;
  }

  header .toggle button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
}
