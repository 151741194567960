.about {
  padding: 0 !important;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}
.about p {
  font-size: 15px;
  /* padding-right: 30px;
  margin-bottom: 20px; */
  line-height: 30px;
  text-align: justify;
}
.about .container{
  width: 100%;
}
.homeVideo{
  height: 500px;
  width: 500px;
}
.Homevdo{
  background-color: #2F2224;
}

@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 20px;
  }
}

.profile{
  width: 200px;
  height: 200px;
  margin-left: 20px;
  border-radius: 50%;
}
.mdlprofile{
  width: 80px;
  height: 45px;
  margin-left: 20px;
  border-radius: 50%;
}
.column5 {
  float: left;
  width: 400px;
  margin-bottom: 16px;

}

.wrapper {

  display: flex;
  align-items: center;
  justify-content: center;

}

.wrapper a {
  display: inline-block;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  
  border-radius: 3px;
  color: white;
}

.modalC {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, .7);
  transition: all .4s;
  margin-top: 10px;
}

.modalC:target {
  visibility: visible;
  opacity: 1;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  max-width: 80%;
  background: #fff;
  /* background-color: #D9AFD9 !important;
  background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); */
  padding: 1em 2em;
}


.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  text-decoration: none;
}
@media screen and (max-width: 650px) {
  .column5 {
    width: 80%;
    display: block;
    
  }
  .profile{
    width: 120px;
    height: 100px;
    margin-left: 0px;
    margin-right: 20px;
    border-radius: 50%;
  }
 
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.container3 {
  padding: 0 16px;
  text-align: center;
}

.container3::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: black;
  text-align: center;
  margin-top: 10px;
}

.button {
  border: none;
  margin-top: 10px;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  /* background-color: #000; */
  cursor: pointer;
}

.cardbtn{
  text-align: center;
}
.button:hover {
  background-color: #555;
}

.teamcard {
  box-shadow: 0 4px 8px 0 rgba(218, 15, 15, 0.2) !important;
  padding: 20px !important;
  /* background-color: #D9AFD9 !important;
  background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); */
  margin-bottom: 10px !important;
  height:auto !important;
  margin-left: 120px !important;
  background-color: white;
}

.cardSection{
/* margin-left: 70px; */
margin-right: 50px;
}
.video {
  width: 100%;
  height: 250px;
  object-fit: fill;
  padding: 0px !important;
  margin: 0px !important;
}
