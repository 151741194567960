.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;


}
.featured{
  margin-top: 0.5px;
  margin-bottom: 10px;
}
.featured img {
  width: 400px;
  height: 255px;
  /* margin-right: 30px; */
  margin-left: 20px;
  /* border-radius: 20px; */
   
}

.container1 {
  max-width: 100%;
  margin-left: 20px;
  margin-right: 20px;

}

.paragraph{
  margin-left: 30px;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 70%;
  text-align: justify;
}


.btncs{
  margin-left: 15px;
}

@media screen and (max-width: 800px){
  .container1{
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    
  }

  .flex{
    display: flex;
    /* flex-direction: column-reverse !important; */
    
  }
  .paragraph{
    margin-bottom: 10px !important;
    margin-top: 10px;
    width: 205px;
    text-align: justify;
  }
  .featured img {
    width: 200px;
    height: 175px;
   
   
  }
  .btncs{
  margin-left: 30px;
   margin-bottom: 10px;
  }
}

.column2 {
  float: right ;
  padding: 0 10px;
}

.column3 {
  float: left;
  width: 50% !important;
  padding: 0 10px;
}
/* Remove extra left and right margins, due to padding */
.row {margin: 0 -5px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: flex;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 800px) {
  .column2 {
    width: auto;
    display: block;
    margin-bottom: 20px;
  }
  .column3 {
    float: left;
    padding: 0 10px;
  }
  
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(218, 15, 15, 0.2);
  padding: 16px;
  /* text-align: center; */
  background-color: #f1f1f1;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 300ms;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}