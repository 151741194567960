.hero {
  width: 100%;
  height: 90vh;
  /* background: url('../../../../public/images/cyber-bg.png') no-repeat center center/cover; */
  background-color: #39517F;
  /* background-image: linear-gradient(19deg, #3EECAC 0%, #d39cce 100%); */
}

/* .hero .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */
.hero .container {
  width: 100%;
  /* height: 100%; */
}

.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  /* padding: 1.5rem; */
  /* margin-left: 6rem; */
}
.heroMain{
  height: 100%;
}
.content.vdo {
  /* background-color: #D9AFD9; */
  /* background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); */
  border-radius: 10px;
  margin: 20px;
}

.img-border {
  z-index: 0;
}

.position-relative {
  position: relative !important;
}

.homeVideo {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.content .text {
  margin: auto;
}

.vdo {}

.hero h1 {
  margin: 0 0 10px 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
  color: white;
}

.hero h2 {
  color: whitesmoke;
  margin-bottom: 50px;
  font-size: 24px;
}

.hero .blue {
  color: var(--primary-color);
}

@media screen and (max-width:940px) {
  .hero .content {
   height: 100%;
   display: flex;
   flex-direction: column;
   /* padding: 1px 0px 1px 3px; */
   justify-content: space-around;
   /* margin-left: 10px; */

  }

  .hero .container {
    height: auto;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    justify-content: end;
    align-content: center;
  }
  .hero{
    height: auto !important;
  }
  .hero h1 {
    margin: 0 0 0px 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    color: white;
  }
  .hero h2 {
    font-size: 18px;
    margin-right: 10px;
    text-align: justify;
  }
}

@media screen and (max-width :500px) {
  .homeVideo {
    height: 62% !important;
    width: 300px !important;
    border-radius: 5px;
  }
  .hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1px 1px 1px 3px;
    justify-content: space-around;
    margin-left: 10px;
 
   }
   .hero h1 {
    margin: 0 0 10px 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 46px;
    color: white;
  }
   .hero .container {
     height: auto;
     display: grid;
     grid-template-columns: repeat(1,1fr);
     justify-content: end;
     align-content: center;
   }
   .hero{
    height: auto !important;
  }
}